@font-face {
    font-family: "Inter";
    src: url("../fonts/Inter/static/Inter-Regular.ttf")
}

@font-face {
    font-family: "Inter";
    font-weight: bold;
    src: url("../fonts/Inter/static/Inter-Bold.ttf")
}

body {
    font-family: Inter;
}

div {
    color: #313537;
}

select {
    color: #313537 !important;
} 

button {
    cursor: pointer;
}

input {
    font-family: Avenir;
}

.full-screen-view {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}

.bold {
    font-weight: bold;
}