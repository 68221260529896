.not-found-view {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    background-blend-mode: overlay,
    normal;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%),
    #1B93C5;

    .lw-logo {
        width: 242px;
        height: 90px;
        margin: 204px 0px 92px 0px;
    }

    .not-found-label {
        color: white;
        font-size: 24px; 
    }
}