.user-pane {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 100;

    .background {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    .user-content-frame {
        position: absolute;
        top: 8px;
        right: 10px;
        padding: 5px;
        border-radius: 4px;
        background: white;
        filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.16));

        .user-content-button {
            border: none;
            width: 125px;
            padding: 4px;
            font-size: 12px;
            font-weight: bold;
            background: none;
        }

        .red {
            color: red;
        }
    }
}