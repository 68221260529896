.floating-input-view {
    position: relative;
    min-width: 100px;
    height: 38px;

    .floating-label {
        position: absolute;
        z-index: 0;
        top: 11px;
        font-size: 12px;
        transition: 0.3s;
        color: #73787B;
        font-weight: lighter;
        background: transparent;
        text-transform: capitalize;
    }

    .floating-label.focus {
        top: 2px;
    }

    .floating-input {
        position: absolute;
        height: 100%;
        z-index: 1;
        bottom: 0px;
        width: 100%;
        margin: 0px;
        border: none;
        outline: none;
        font-size: 12px;
        color: #313537;
        font-weight: bold;
        border-radius: 0px;
        box-sizing: border-box;
        background: transparent;
        padding: 18px 0px 3px 0px;
    }
}