.healthsnap-section-view {
    display: flex;
    flex-direction: column;

    .toolbar {
        display: flex;
        padding: 16px;
        flex: 0 0 60px;
        align-items: center;
        box-sizing: border-box;
        justify-content: space-between;
        border-bottom: 1px solid #CFD4D7;

        .cancel-button,
        .print-button {
            position: relative;
            z-index: 1;
            border: none;
            font-size: 14px;
            background: none;
            color: #73787B;
        }

        .print-button {
            color: #1B93C5;
        }

        .page-header-frame {
            position: absolute;
            left: 0px;
            right: 0px;
            z-index: 0;
            display: flex;
            align-items: center;
            flex-direction: column;
            justify-content: center;

            .section-name {
                font-size: 14px;
                font-weight: bold;
                line-height: 130%;
            }

            .section-detail {
                display: flex;

                .section-label,
                .status {
                    font-size: 12px;
                }

                .status {
                    margin-left: 4px;
                    text-transform: capitalize;
                }
            }
        }
    }

    .content-frame {
        flex: 1 1;
        overflow-y: scroll;

        .section-frame {
            padding: 30px;
            border-bottom: 1px solid #CFD4D7;

            .section-header {
                font-size: 20px;
                font-weight: 600;
                line-height: 24px;
                margin-bottom: 36px;
            }

            .section-document-frame {
                width: 90%;
                margin: auto;
                max-width: 1080px;

                .form-frame {
                    .questions-list-view {
                        margin: 0px;
                        padding: 0px;
                        display: flex;
                        flex-wrap: wrap;
                        list-style: none;

                        .section-question-cell {
                            flex: 0 0 33.33%;
                            margin-bottom: 40px;

                            .section-key,
                            .section-value {
                                font-size: 12px;
                                line-height: 160%;
                            }

                            .section-key {
                                color: #73787B;
                                text-transform: capitalize;
                            }

                            .section-value {
                                font-weight: bold;
                            }

                            .section-value.hint {
                                color: #73787B;
                                font-weight: normal;
                            }
                        }
                    }
                }
            }
        }

        .groups-list-view {
            margin: 0px;
            padding: 0px;
            list-style: none;

            .insurances-list-view {
                margin: 0px;
                padding: 0px;
            }

            .section-table-view {
                overflow: hidden;
                border-radius: 4px;
                border: 1px solid #CFD4D7;

                .table-headers {
                    height: 28px;
                    display: flex;
                    padding: 0px 16px;
                    align-items: center;
                    background: #F6F8F8;
                    box-sizing: border-box;
                    border-bottom: 1px solid #CFD4D7;

                    .table-header {
                        font-size: 12px;
                    }

                    .name {
                        flex: 0 0 28%;
                    }

                    .relationship {
                        flex: 0 0 20%;
                    }

                    .address {
                        flex: 0 0 28%;
                    }

                    .phone {
                        flex: 1 1;
                    }

                    .title, .start-date, .status {
                        flex: 0 0 25%;
                    }

                    .title {
                        flex: 0 0 50%;
                        padding-left: 16px;
                    }

                    .long {
                        flex: 0 0 75%;
                    }
                }

                .section-list-view {
                    margin: 0px;
                    padding: 0px;
                    list-style: none;

                    .section-cell {
                        padding: 16px;
                        display: flex;
                        align-items: center;
                        box-sizing: border-box;
                        border-bottom: 1px solid #CFD4D7;

                        .cell-label {
                            font-size: 12px;
                            margin-right: 1%;
                        }

                        .name {
                            flex: 0 0 27%;
                            font-weight: bold;
                        }

                        .relationship {
                            flex: 0 0 19%;
                        }

                        .address {
                            flex: 0 0 27%;
                        }

                        .phone {
                            flex: 1 1;
                        }

                        .title, .start-date, .status {
                            flex: 0 0 24%;
                        }

                        .title {
                            flex: 0 0 49%;
                            padding-left: 16px;
                        }

                        .long {
                            flex: 0 0 74%;
                        }
                    }

                    .section-cell:last-child {
                        border: none;
                    }
                }
            }
        }
    }
}