.getting-started-view {
    display: flex;
    flex-direction: column;

    .toolbar {
        display: flex;
        flex: 0 0 48px;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #CFD4D7;

        .page-header {
            font-size: 14px;
            font-weight: bold;
        }
    }

    .content-frame {
        flex: 1 1;
        overflow-y: scroll;

        .document-frame {
            width: 90%;
            margin: auto;
            max-width: 960px;

            .banner-icon {
                width: 100%;
                margin-top: 40px;
            }

            .section-frame {
                margin-top: 40px;

                .section-header {
                    font-size: 20px;
                    font-weight: bold;
                }

                .form-frame {
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 36px;

                    .floating-input-view {
                        flex: 0 0 31%;
                        margin-bottom: 40px;
                        border-bottom: 1px solid #CFD4D7;
                    }

                    .middle {
                        margin: 0px 2%;
                    }
                }

                .checkbox-frame {
                    display: flex;
                    align-items: center;

                    .checkbox {
                        margin-right: 12px;
                    }

                    .checkbox-label {
                        font-size: 12px;

                        .provider-link,
                        .provider-link:visited {
                            color: #1B93C5;
                            margin-left: 5px;
                        }
                    }
                }
            }

            .button-frame {
                display: flex;
                margin-top: 40px;
                padding-top: 20px;
                justify-content: flex-end;
                border-top: 1px solid #CFD4D7;

                .complete-account-button {
                    border: none;
                    color: white;
                    font-size: 12px;
                    font-weight: bold;
                    padding: 12px 14px;
                    border-radius: 4px;
                    background: #1B93C5;
                    box-sizing: border-box;
                }

                .disabled {
                    cursor: not-allowed;
                    background: #CFD4D7;
                }
            }
        }
    }
}