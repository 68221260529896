.dashboard-view {
    display: flex;
    flex-direction: column;

    .toolbar {
        width: 100%;
        display: flex;
        flex: 0 0 48px;
        padding: 0px 15px;
        align-items: center;
        box-sizing: border-box;
        justify-content: space-between;
        border-bottom: 1px solid #CFD4D7;

        .dashboard-button {
            cursor: pointer;

            .app-name {
                color: #1B93C5;
                font-size: 14px;
                font-weight: 600;
            }
        }

        .user-button {
            display: flex;
            cursor: pointer;

            .user-frame {
                .user, .org {
                    font-size: 12px;
                    color: #73787B;
                    text-align: right;
                }

                .user {
                    color: #313537;
                    font-weight: 600;
                }
            }

            .caret-icon {
                width: 24px;
                height: 24px;
                margin-left: 6px;
            }
        }
    }

    .content-frame {
        position: absolute;
        top: 48px;
        left: 0px;
        right: 0px;
        bottom: 0px;

        .health-snaps-table-view {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;

            .table-headers {
                display: flex;
                flex: 0 0 28px;
                align-items: center;
                background: #F6F8F8;
                // border-top: 1px solid #CFD4D7;
                justify-content: space-between;
                border-bottom: 1px solid #CFD4D7;
                
                .table-header {
                    flex: 0 0 19%;
                    font-size: 12px;
                    line-height: 100%;
                }

                .patient {
                    padding-left: 62px;
                }
            }

            .healthsnaps-list-view::-webkit-scrollbar {
                display: none;
            }

            .healthsnaps-list-view {
                flex: 1 1;
                margin: 0px;
                padding: 0px;
                display: flex;
                overflow: auto;
                list-style: none;
                flex-direction: column;

                .healthsnap-cell {
                    width: 100%;
                    height: 48px;
                    display: flex;
                    cursor: pointer;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: 1px solid #CFD4D7;

                    .cell-label {
                        flex: 0 0 19%;
                        font-size: 12px;
                        line-height: 130%;
                        text-transform: capitalize;
                    }

                    .status {
                        font-weight: bold;
                    }

                    .patient-frame {
                        flex: 0 0 19%;
                        display: flex;
                        padding-left: 62px;
                        align-items: center;

                        .profile-picture {
                            width: 32px;
                            height: 32px;
                            flex: 0 0 32px;
                            margin-right: 12px;
                        }

                        .initials-frame {
                            flex: 0 0 32px;
                            width: 32px;
                            height: 32px;
                            display: flex;
                            margin-right: 12px;
                            border-radius: 50%;
                            background: #BAD8B4;
                            align-items: center;
                            justify-content: center;

                            .initials {
                                color: white;
                                font-size: 12px;
                                font-weight: bold;
                                text-transform: uppercase;
                            }
                        }

                        .cell-label {
                            flex: 1 1;
                        }
                    }
                }

                .healthsnap-cell:hover {
                    background: white;
                    filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.16));

                    .cell-label {
                        font-weight: bold;
                    }
                }
            }
        }
    }
}