/* The container */
.checkbox {
    position: relative;
    width: 16px;
    height: 16px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox input {
    position: absolute;
    opacity: 0;
    z-index: 1;
    margin: 0px;
    width: 100%;
    height: 100%;
    cursor: pointer;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 14px;
    height: 14px;
    z-index: 0;
    border-radius: 4px;
    background: #F2F2F2;
    border: 1px solid #DBDBDB;
}

/* When the checkbox is checked, add a blue background */
.checkbox input:checked~.checkmark {
    border-color: #1B93C5;
    background-color: #1B93C5;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox .checkmark:after {
    top: 1px;
    left: 5px;
    width: 3px;
    height: 8px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}


/* Radio checkboxes */
.radio-checkbox {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #F6F8F8;
    box-sizing: border-box;
    border: 1px solid #CFD4D7;
}

.radio-checkbox.checked {
    background: white;
    border: 5px solid #1B93C5;
}