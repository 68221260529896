.healthsnap-view {
    display: flex;
    flex-direction: column;

    .toolbar {
        display: flex;
        padding: 16px;
        flex: 0 0 48px;
        align-items: center;
        box-sizing: border-box;
        justify-content: space-between;
        border-bottom: 1px solid #CFD4D7;

        .cancel-button,
        .print-button {
            position: relative;
            z-index: 1;
            border: none;
            font-size: 14px;
            background: none;
            color: #73787B;
        }

        .print-button {
            color: #1B93C5;
        }

        .page-header {
            position: absolute;
            left: 0px;
            right: 0px;
            z-index: 0;
            font-size: 14px;
            font-weight: 600;
            text-align: center;
        }
    }

    .content-frame {
        flex: 1 1;
        overflow-y: scroll;

        .section-frame {
            border-bottom: 1px solid #CFD4D7;

            .section-document-frame {
                width: 90%;
                margin: auto;
                padding: 30px;
                max-width: 1080px;
                box-sizing: border-box;

                .section-header {
                    font-weight: 600;
                    font-size: 32px;
                    text-transform: capitalize;
                }

                .section-subheader {
                    font-weight: 600;
                    font-size: 20px;
                }

                .user-details-frame {
                    display: flex;
                    margin-top: 24px;
                    justify-content: space-between;

                    .user-detail-frame {
                        flex: 0 0 30%;
                        padding-left: 20px;
                        border-left: 1px solid #CFD4D7;

                        .user-label {
                            font-size: 12px;
                            line-height: 160%;
                        }

                        .user-label.hint {
                            color: #73787B;
                            font-style: italic;
                        }
                    }

                    .user-detail-frame.first {
                        border: none;
                        padding: 0px;
                    }
                }

                .sections-table-view {
                    display: flex;
                    overflow: hidden;
                    margin-top: 12px;
                    border-radius: 4px;
                    flex-direction: column;
                    border: 1px solid #CFD4D7;

                    .table-headers {
                        display: flex;
                        flex: 0 0 30px;
                        padding: 0px 16px;
                        align-items: center;
                        background: #F6F8F8;
                        box-sizing: border-box;
                        border-bottom: 1px solid #CFD4D7;

                        .table-header {
                            flex: 1 1;
                            font-size: 12px;
                        }

                        .name {
                            flex: 0 0 60%;
                        }
                    }

                    .sections-list-view {
                        margin: 0px;
                        padding: 0px;
                        display: flex;
                        list-style: none;
                        flex-direction: column;

                        .section-cell {
                            display: flex;
                            flex: 0 0 48px;
                            padding: 0px 16px;
                            align-items: center;
                            box-sizing: border-box;
                            border-bottom: 1px solid #CFD4D7;

                            .cell-label {
                                flex: 1 1;
                                font-size: 12px;
                                font-weight: 600;
                                line-height: 15px;
                            }

                            .name {
                                flex: 0 0 60%;
                            }

                            .status-frame {
                                flex: 1 1;
                                display: flex;
                                align-items: center;
                                justify-content: space-between;

                                .cell-label {
                                    color: #3BC86B;
                                }

                                .view-results-button {
                                    border: none;
                                    font-size: 12px;
                                    color: #1B93C5;
                                    background: none;
                                    padding: 6px 11px;
                                    font-weight: bold;
                                    border-radius: 4px;
                                    border: 1px solid #1B93C5;
                                }

                                .view-results-button:hover {
                                    color: white;
                                    background: #1B93C5;
                                }
                            }
                        }

                        .section-cell:last-child {
                            border: none;
                        }
                    }
                }
            }
        }
    }
}