.login-view {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    background-blend-mode: overlay, normal;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.3) 0%, rgba(0, 0, 0, 0) 100%), #1B93C5;

    .lw-logo {
        width: 242px;
        height: 90px;
        margin: 204px 0px 92px 0px;
    }

    .form-frame {
        width: 480px;
        // height: 296px;
        display: flex;
        border-radius: 8px;
        background: white;
        flex-direction: column;

        .form-input-frame {
            display: flex;
            padding: 36px 60px;
            flex-direction: column;

            .form-header {
                font-size: 20px;
                font-weight: 600;
                line-height: 24px;
                margin-bottom: 24px;
            }

            .form-input {
                font-size: 12px;
                font-weight: 600;
                padding: 9px 12px;
                border-radius: 4px;
                margin-bottom: 4px;
                box-sizing: border-box;
                border: 1px solid #CFD4D7;
            }

            .form-input:placeholder-shown {
                color: #73787B;
                font-weight: normal;
            }

            .sign-in-button {
                width: 100%;
                border: none;
                flex: 0 0 36px;
                color: white;
                font-size: 12px;
                margin-top: 8px;
                font-weight: 600;
                border-radius: 4px;
                background: #3BC86B;
            }
        }
    }

    .toolbar-frame {
        width: 100%;
        display: flex;
        flex: 0 0 60px;
        align-items: center;
        justify-content: center;
        border-top: 1px solid #CFD4D7;

        .toolbar-label {
            font-size: 12px;
            line-height: 100%;
        }

        .request-one-button {
            border: none;
            font-size: 12px;
            padding: 0px 4px;
            background: none;
            color: #1B93C5;
            font-weight: bold;
            line-height: 100%;
            text-decoration: none;
        }
    }
}